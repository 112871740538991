import * as React from 'react'
import * as MantineCore from '@mantine/core'
import * as Icons from 'tabler-icons-react'

interface Props {
	value: number | undefined
	upperValue: number
	onChange: (value?: number) => void
}
const BudgetInput = ({ value, upperValue, onChange }: Props) => {
	return (
		<div id="budget-filter">
			<div id="budget-slider">
				<MantineCore.Slider
					color="yellow"
					size="xl"
					thumbChildren={<Icons.CurrencyDollar size="1rem" />}
					thumbSize={26}
					value={value}
					defaultValue={value}
					min={0}
					thumbLabel="budget slider"
					max={upperValue}
					onChange={(val) => onChange(+val === 0 ? undefined : +val)}
					label={(value) => `$${value.toLocaleString()}`}
				/>
			</div>
			<div id="budget-input">
				<MantineCore.NumberInput
					value={value !== undefined ? +value : undefined}
					onChange={(val) => onChange(+val === 0 ? undefined : +val)}
					size="xs"
					aria-label="your budget"
				/>
			</div>
		</div>
	)
}

export default BudgetInput
