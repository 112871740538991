import React, { ReactNode } from 'react'
import * as MantineCore from '@mantine/core'

interface GenericProps<T> {
	// label?: ReactNode
	items: { display: string | ReactNode; value: T }[]
	value?: T
	nullable?: boolean
	onChange: (selected: T[]) => void
	size?: string
}

type Option<T> = {
	enabled: boolean
	label: string | ReactNode
	value: T
}

const MultiSelectButtonGroup = <T extends string | number | boolean>({
	// label,
	items,
	// value,
	// nullable = true,
	onChange,
	size = 'sm',
}: GenericProps<T | undefined>) => {
	const [options, setOptions] = React.useState<Option<T>[]>(
		items.map((item) => ({
			enabled: false,
			label: item.display,
			value: item.value as T,
		})),
	)
	const selectedOptions = options.filter((option) => option.enabled)

	React.useEffect(() => {
		if (selectedOptions.length === 0) {
			// raise 'all' as default
			onChange(options.map((opt) => opt.value))
		} else {
			onChange(selectedOptions.map((option) => option.value))
		}
	}, [options])

	const toggle = (toggleKey: number) => {
		const newOptions = options.map((option, optionKey) =>
			optionKey === toggleKey
				? { ...option, enabled: !option.enabled }
				: { ...option },
		)
		setOptions(newOptions)
	}

	return (
		<div className="toggle-filter-control">
			{/* {label && (
				<div className="label">
					{label}
					<br />
				</div>
			)} */}
			<MantineCore.Button.Group>
				{options.map((option, key) => (
					<MantineCore.Button
						key={key}
						size={size}
						radius="sm"
						color={option.enabled ? 'yellow' : 'gray'}
						variant={option.enabled ? 'light' : 'default'}
						style={{
							...{ color: option.enabled ? 'brown' : undefined },
						}}
						onClick={() => toggle(key)}
					>
						{option.label}
					</MantineCore.Button>
				))}
			</MantineCore.Button.Group>
		</div>
	)
}

export default MultiSelectButtonGroup
