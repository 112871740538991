import React from 'react'
import * as ReactRedux from 'react-redux'
import * as MantineCore from '@mantine/core'
import * as Icons from 'tabler-icons-react'

import * as Types from '../declarations'
import * as Actions from '../redux/actions'
import * as Selectors from '../redux/selectors'

interface MyComponentProps {
	variant: 'small' | 'modal' | 'banner' | 'alert'
	version: Types.VERSION
	closeModal?: () => void
}

const AccountNudge: React.FC<MyComponentProps> = ({
	variant,
	version,
	closeModal,
}: MyComponentProps) => {
	const dispatch = ReactRedux.useDispatch()

	const openSignUp = () => {
		closeModal?.()
		dispatch(Actions.openSignUp())
	}

	const authedUser = ReactRedux.useSelector(Selectors.authedUser)

	const isAuthedUser = !!authedUser

	const title = `Join our community of 
	${version === Types.VERSION.PEAKS ? 'mountaineers' : 'trekkers'}`

	return (
		<div className="account-nudge">
			{variant === 'small' && <div>Small content</div>}
			{variant === 'modal' && (
				<div>
					<MantineCore.Card shadow="sm" padding="lg" radius="md" withBorder>
						<MantineCore.Card.Section>
							<MantineCore.Image
								src={`/static/${
									version === Types.VERSION.PEAKS ? 'peaks' : 'trekking'
								}_600.jpeg`}
								// height={160}
								alt={`Guided ${
									version === Types.VERSION.PEAKS ? 'peaks' : 'trekking'
								}`}
							/>
						</MantineCore.Card.Section>

						<MantineCore.Group position="apart" mt="md" mb="xs">
							<MantineCore.Text weight={500}>{title}</MantineCore.Text>
							{/* <MantineCore.Badge color="pink" variant="light">
								On Sale
							</MantineCore.Badge> */}
						</MantineCore.Group>

						<MantineCore.Text size="sm" color="dimmed">
							<p>Join our community so that you can:</p>
							<MantineCore.List
								spacing="xs"
								size="sm"
								center
								icon={
									<MantineCore.ThemeIcon color="teal" size={24} radius="xl">
										<Icons.CircleCheck size="1rem" />
									</MantineCore.ThemeIcon>
								}
							>
								<MantineCore.List.Item
									icon={
										<MantineCore.ThemeIcon size={24} radius="xl">
											<Icons.PhotoUp size="1rem" />
										</MantineCore.ThemeIcon>
									}
								>
									Contribute pictures of routes
								</MantineCore.List.Item>
								{/* // todo: enable these once features are ready */}
								{/* <MantineCore.List.Item>
									Suggest new routes
								</MantineCore.List.Item>
								<MantineCore.List.Item>
									Wishlist trips you&apos;re interested in
								</MantineCore.List.Item> */}
								<MantineCore.List.Item
									icon={
										<MantineCore.ThemeIcon size={24} radius="xl">
											<Icons.Pencil size="1rem" />
										</MantineCore.ThemeIcon>
									}
								>
									Edit/correct/add information for routes
								</MantineCore.List.Item>
								{/* // todo: ask/answer questions
								// todo: get notified about new routes 
								// todo: receive discounted trips */}
							</MantineCore.List>
						</MantineCore.Text>

						<MantineCore.Button
							variant="light"
							color="blue"
							fullWidth
							mt="md"
							radius="md"
							onClick={openSignUp}
						>
							Join
						</MantineCore.Button>
					</MantineCore.Card>
				</div>
			)}
			{variant === 'banner' && <div>Banner content</div>}
			{variant === 'alert' && !isAuthedUser && (
				<MantineCore.Alert title={title} mb="xs">
					<MantineCore.Group noWrap align="center">
						<div style={{ flexGrow: 1, marginRight: 10 }}>
							{/* Text takes up the remaining space */}
							Connect with guides, receive news on new expeditions/routes,
							upload pictures, share/edit routes.
						</div>
						<MantineCore.Button
							// variant="light"
							color="blue"
							radius="md"
							onClick={openSignUp}
							style={{ whiteSpace: 'nowrap' }} // Ensures the button text does not wrap
						>
							Join
						</MantineCore.Button>
					</MantineCore.Group>
				</MantineCore.Alert>
			)}
		</div>
	)
}

export default AccountNudge
