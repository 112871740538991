import React, { useState, useEffect } from 'react'
// import Head from 'next/head'
import classNames from 'classnames'
import * as Redux from 'react-redux'
import * as Icons from 'tabler-icons-react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

import * as Types from '../declarations'
import * as Data from '../lib/data'
import * as HelperUtil from '../lib/helper'
import * as DisplayUtil from '../lib/display'
import { useIsMobile } from '../lib/useIsMobile'

import PageTemplate from '../components/PageTemplate'
import SearchFilter from '../components/SearchFilter'
import TrekCard from '../components/TrekCard'
import Map from '../components/Mapbox'
// import VersionContext from '../contexts/VersionContext'
import { withVersion } from '../lib/withVersion'
import * as Actions from '../redux/actions'
import AccountNudge from 'components/AccountNudge'

interface HomePageProps {
	treks: Types.App.HomePageTrip[]
	version: Types.VERSION
}

const treksMatchingMonths = (
	treks: Types.App.HomePageTrip[],
	months: number[],
) => {
	if (months.length === 12) {
		// return all treks, as user has no month preference
		return treks
	}

	return treks.filter(
		(trek) =>
			(trek.bestMonths ?? []).filter((month) => months.includes(+month))
				.length > 0,
	)
}

const geoFilteredTreks = (
	treks: Types.App.HomePageTrip[],
	mapBounds: Types.MapBounds | undefined,
	mapMode: boolean,
) => {
	// todo: remove true, after I add locations for all treks
	if (!mapMode || !mapBounds) {
		return treks
	}
	const { _ne, _sw } = mapBounds

	return treks.filter((trek) => {
		const location = trek?.routeLocation ?? undefined

		return (
			location &&
			location.latitude > _sw.lat &&
			location.latitude < _ne.lat &&
			location.longitude > _sw.lng &&
			location.longitude < _ne.lng
		)
	})
}

const treksMatchingCountries = (
	treks: Types.App.HomePageTrip[],
	countries: string[],
) => {
	if (countries.length === 0) {
		// no filter applied, return all treks
		return treks
	}
	return treks.filter(
		(trek) =>
			trek.countries.filter((trekCountry) => countries.includes(trekCountry))
				.length > 0,
	)
}

const treksMatchingContinents = (
	treks: Types.App.HomePageTrip[],
	continents: string[],
) => {
	if (continents.length === 0) {
		// no filter applied, return all treks
		return treks
	}
	return treks.filter((trek) => continents.includes(trek.continent))
}

const treksMatchingDuration = (
	treks: Types.App.HomePageTrip[],
	duration?: string,
) => {
	if (!duration) {
		return treks
	}

	return treks.filter((trek) => {
		const tripDuration = trek.totalDaysLength?.[0]

		if (!tripDuration) {
			return false
		}

		switch (duration) {
			case '1 wk':
				return tripDuration <= 7
			case '2 wk':
				return tripDuration <= 14
			case '3 wk':
				return tripDuration <= 21
			case '4 wk':
				return tripDuration <= 28
			case '4+ wk':
				return tripDuration > 28
		}
	})
}

const heightFilteredTreks = (
	treks: Types.App.HomePageTrip[],
	heightFilter?: number,
) => {
	if (!heightFilter) {
		return treks
	}

	return treks.filter((trek) => {
		const maxHeight = trek?.height

		if (!maxHeight) {
			return false
		}

		return maxHeight >= heightFilter
	})
}

const budgetFilteredTrips = (
	trips: Types.App.HomePageTrip[],
	budgetFilter?: number,
) => {
	if (!budgetFilter) {
		return trips
	}

	return trips.filter((trip) => {
		const cost = trip?.lowestCost

		if (!cost) {
			return false
		}

		return cost <= budgetFilter
	})
}

const tripsMatchingSimpledifficulty = (
	trips: Types.App.HomePageTrip[],
	difficulties: number[],
) => {
	if (!difficulties || difficulties.length === 0 || difficulties.length === 6) {
		return trips
	}

	return trips.filter(
		(trip) =>
			(trip?.climbDifficulty ?? []).filter((diff) =>
				difficulties.includes(diff),
			).length > 0,
	)
}

const tripsMatchingTrekkingDifficulty = (
	trips: Types.App.HomePageTrip[],
	difficulties: number[],
) => {
	if (!difficulties || difficulties.length === 3) {
		return trips
	}

	return trips.filter((trip) => {
		if (!trip?.trekDifficulty) {
			return false
		}
		// easy
		if (difficulties.includes(0) && trip.trekDifficulty < 5) {
			return true
		}

		// medium
		if (
			difficulties.includes(1) &&
			trip.trekDifficulty >= 5 &&
			trip.trekDifficulty < 7.5
		) {
			return true
		}

		// hard
		if (difficulties.includes(2) && trip.trekDifficulty >= 7.5) {
			return true
		}

		return false
	})
}

const permitFilteredTreks = (
	treks: Types.App.HomePageTrip[],
	withoutPermitOnly: boolean,
) => (!withoutPermitOnly ? treks : treks.filter((trek) => !trek.permitsNeeded))

const routeTypeFilteredTreks = (
	treks: Types.App.HomePageTrip[],
	routeType?: 'TREK' | 'CLIMB',
) => {
	return !routeType
		? treks
		: treks.filter((trek) => routeType === trek.trekOrClimb)
}

const HomePage = ({ treks, version }: HomePageProps) => {
	const blankSearchFilter: Types.SearchFilter = {
		text: '',
		timeOfYear: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		countryFilters: [],
		continentFilters: [],
		mapBounds: undefined,
		heightFilter: undefined,
		isWithoutPermit: false,
		routeTypeFilter: undefined,
		simpleDifficultyFilter: undefined,
		trekkingDifficultyFilter: undefined,
		budgetFilter: undefined,
	}

	const [searchFilter, setSearchFilter] = React.useState<Types.SearchFilter>(
		blankSearchFilter,
	)
	const [filteredTreks, setFilteredTreks] = useState(treks)
	const [mapMode, setMapMode] = useState(true)

	const isMobile = useIsMobile()
	const router = useRouter()
	const dispatch = Redux.useDispatch()
	// const version = React.useContext(VersionContext)

	useEffect(() => {
		const {
			text,
			timeOfYear,
			countryFilters,
			continentFilters,
			mapBounds,
			duration,
			heightFilter,
			isWithoutPermit,
			routeTypeFilter,
			budgetFilter,
			simpleDifficultyFilter,
			trekkingDifficultyFilter,
		} = searchFilter
		// filter on free text input
		const textFilteredTreks = treks.filter((trek) =>
			trek.name.toLowerCase().includes(text.toLowerCase()),
		)
		// filter on set months
		const bestTimeOfYearFiltered = treksMatchingMonths(
			textFilteredTreks,
			timeOfYear,
		)

		// country filter
		const countryFilteredTreks = treksMatchingCountries(
			bestTimeOfYearFiltered,
			countryFilters,
		)

		const continentFilteredTreks = treksMatchingContinents(
			countryFilteredTreks,
			continentFilters,
		)

		const geoFiltered = geoFilteredTreks(
			continentFilteredTreks,
			mapBounds,
			mapMode,
		)

		const heightFiltered = heightFilteredTreks(geoFiltered, heightFilter)

		const budgetFiltered = budgetFilteredTrips(heightFiltered, budgetFilter)

		const durationFiltered = treksMatchingDuration(budgetFiltered, duration)

		const permitFiltered = permitFilteredTreks(
			durationFiltered,
			isWithoutPermit,
		)

		const routeTypeFiltered = routeTypeFilteredTreks(
			permitFiltered,
			routeTypeFilter,
		)

		const mountaineeringDifficultyFilteredTreks = tripsMatchingSimpledifficulty(
			routeTypeFiltered,
			simpleDifficultyFilter as number[],
		)

		const trekkingDifficultyFilteredTrips = tripsMatchingTrekkingDifficulty(
			mountaineeringDifficultyFilteredTreks,
			trekkingDifficultyFilter as number[],
		)

		setFilteredTreks(trekkingDifficultyFilteredTrips)
	}, [searchFilter, mapMode])

	useEffect(() => {
		if (isMobile && mapMode) {
			setMapMode(false)
		}
	}, [mapMode, isMobile])

	useEffect(() => {
		// Check if the URL has the specific hash
		if (router.asPath.includes('#open-login-guide')) {
			dispatch(Actions.openLogin())
		}
	}, [router.asPath, dispatch])

	const suggestATrip = () => {
		dispatch(
			Actions.openSuggestFeedbackModal(
				Types.App.DataTypes.FeedbackSection.SUGGEST_ROUTE,
			),
		)
	}

	const seo = {
		title:
			version === Types.VERSION.TREKKING
				? 'Guided Trekking - find your next trek'
				: 'Guided Peaks - find your next climb',
		// todo: make this site specific, and inject relevant keywords etc
		description:
			version === Types.VERSION.TREKKING
				? 'Curated guide of treks for hikers, walkers, & trekkers. Information for each route to compare & find your best next route.'
				: 'Curated guide of peaks for climbers & mountaineers. Information for each route to compare & find your best next route.',

		canonical: `https://www.guided${
			version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'
		}.com`,
		siteName:
			version === Types.VERSION.TREKKING ? 'Guided Trekking' : 'Guided Peaks',
	}

	const seoImages = treks
		.filter((trek, i) => i <= 5)
		.map((trip) => ({
			url: DisplayUtil.getUploadThumbPath(trip.firstUploadId, 'large'),
			//width: +image.dimensionsLarge.split('x')[0],
			// height: +image.dimensionsLarge.split('x')[1],
			alt: trip.name,
			type: 'image/jpeg',
		}))

	return (
		<>
			<NextSeo
				title={seo.title}
				description={seo.description}
				canonical={seo.canonical}
				openGraph={{
					url: seo.canonical,
					title: seo.title,
					description: seo.description,
					images: seoImages,
					siteName: seo.siteName,
					type: 'website',
					locale: 'en_US',
				}}
			/>

			<PageTemplate isFullWidth={true} isFullHeight={true}>
				{/* <Head> */}
				{/* // todo: still a page title? */}
				{/* {version === Types.VERSION.TREKKING && (
						<title>Guided Trekking - find your next trek</title>
					)}
					{version === Types.VERSION.PEAKS && (
						<title>Guided Peaks - find your next climb</title>
					)} */}
				{/* <meta
						name="viewport"
						content="initial-scale=1, width=device-width, user-scalable=yes"
					/> */}
				{/* <meta
						name="description"
						content="Curated guide of treks & peaks for hikers, walkers, climbers, trekkers, & mountaineers. Information for each route to compare & find your best next route."
					/> */}
				{/* <link rel="canonical" href={seo.canonical} /> */}
				{/* </Head> */}

				<h1 id="home-page-header">
					{version === Types.VERSION.TREKKING
						? 'Curated treks & hikes from around the world - guided & solo trips'
						: 'Find guided mountaineering trips you can join'}
				</h1>
				<div id="header-search">
					<SearchFilter
						mapMode={mapMode}
						currentSearchFilter={searchFilter}
						// treks={filteredTreks}
						treks={treks}
						onChange={setSearchFilter}
						setMapMode={setMapMode}
					/>
				</div>
				<div
					id="map-results-container"
					className={classNames({ 'map-mode-enabled': mapMode })}
				>
					<div id="map-results">
						<div id="map-container">
							{mapMode && (
								<Map
									trips={filteredTreks}
									boundsChanged={(mapBounds) => {
										setSearchFilter({ ...searchFilter, mapBounds })
									}}
								/>
							)}
						</div>
					</div>
					<div id="trek-results">
						<AccountNudge variant={'alert'} version={version} />
						<div className="trek-card-container">
							{filteredTreks.map((trek, key) => (
								<TrekCard
									tripCardProps={HelperUtil.homePageTripToTripCard(trek)}
									key={key}
								/>
							))}
							<div
								id="suggest-a-trip"
								className="trek-card"
								onClick={suggestATrip}
							>
								<div className={'name text'}>
									<Icons.Plus size="3rem" />
									<br /> add a{' '}
									{version === Types.VERSION.PEAKS ? 'climb' : 'trek'}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <Footer /> */}
			</PageTemplate>
		</>
	)
}

export const getServerSideProps = withVersion(
	async (context: { version?: Types.VERSION }) => {
		// const version = React.useContext(VersionContext)
		// console.log('got version on page', context?.version)
		// console.log('got variable on page', context?.variable)
		// console.log('ctx', context)
		//@ts-ignore
		const trips = await Data.getHomePageTrips(context?.version)

		return { props: { treks: trips, version: context?.version } }
	},
)

export default HomePage
