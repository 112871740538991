import Link from 'next/link'
import * as Redux from 'react-redux'
import * as Icons from 'tabler-icons-react'
import * as Types from '../declarations'
// import * as URLUtil from '../lib/urls'
import * as DisplayUtil from '../lib/display'
import * as HelperUtil from '../lib/helper'
import classNames from 'classnames'
import Image from 'next/image'
// @ts-ignore
import iso3311a2 from 'iso-country-lookup'

interface Props {
	tripCardProps: Types.App.TripCardProps
	overloadPrice?: {
		price: number
		currency: string
	}
}

const unitSelector = (state: Types.AppState) => state.unit

const TrekCard = ({ tripCardProps, overloadPrice }: Props) => {
	const {
		name,
		trekOrClimb,
		continent,
		countries,
		slug,
		lowestCost,
		totalDistanceLength,
		totalDaysLength,
		height,
		firstUploadId,
		climbDifficulty,
		trekDifficulty,
		isValidCompletePage,
	} = tripCardProps
	const url = DisplayUtil.getUploadThumbPath(firstUploadId, 'medium')

	const isTrekNotPeak = trekOrClimb === 'TREK'
	const trekRoute = DisplayUtil.getTrekRoute(
		trekOrClimb,
		continent,
		countries,
		slug,
	)

	const unit = Redux.useSelector(unitSelector)

	const distanceOrHeight = DisplayUtil.distanceOrHeightNew(
		totalDistanceLength,
		height,
		unit,
		trekOrClimb,
	)

	const lengthInDays = totalDaysLength
		? DisplayUtil.formatNumberOrRange(totalDaysLength)
		: undefined

	const difficulty =
		trekOrClimb === Types.App.DataTypes.TrekOrClimb.TREK
			? !!trekDifficulty
				? `${Math.floor(trekDifficulty)} / 10`
				: undefined
			: (climbDifficulty ?? []).length > 0
			? DisplayUtil.formatDifficulty(climbDifficulty /*, 'french?'*/)
			: undefined

	const formattedPrice = !!overloadPrice
		? `${
				overloadPrice.currency === 'USD'
					? '$'
					: overloadPrice.currency === 'EUR'
					? '€'
					: overloadPrice.currency === 'GBP'
					? '£'
					: `${overloadPrice.currency}`
		  }${overloadPrice.price.toLocaleString()}`
		: !!lowestCost
		? `$${Number(lowestCost).toLocaleString()}`
		: undefined

	return (
		<div
			className={classNames({
				'trek-card': true,
				'is-holding-page-only': !isValidCompletePage,
			})}
		>
			<Link href={trekRoute} legacyBehavior>
				<a>
					<Image
						src={url}
						alt={''}
						layout="fill"
						objectFit="cover"
						quality={100}
						style={{
							borderRadius: 4,
						}}
					/>
					<div className={'name text-shadow'}>
						{name}
						{/* {!isTrekNotPeak && (
                            <>
                                &nbsp;
                                <i
                                    className="denote-mountain-icon fas fa-mountain"
                                    aria-hidden
                                ></i>
                            </>
                        )} */}
					</div>
				</a>
			</Link>
			{difficulty && (
				<div className={'text-shadow small-detail top-left'}>
					<span>
						<Icons.Stretching size="18px" />
						&nbsp;{difficulty}{' '}
					</span>
				</div>
			)}

			{isTrekNotPeak && lengthInDays && (
				<div className={'text-shadow small-detail bottom-right'}>
					<span>{lengthInDays} days</span>
				</div>
			)}
			{!isTrekNotPeak && countries.length > 0 && (
				<div className={'text-shadow small-detail bottom-right'}>
					<span>
						{countries.map((isoCode: string, countryIndex: number) => (
							<span key={countryIndex} className="country">
								<img
									title={iso3311a2.getCountry(isoCode.toUpperCase())}
									src={HelperUtil.getFlagURL(isoCode)}
									className="flag"
								/>
							</span>
						))}
					</span>
				</div>
			)}

			{formattedPrice && (
				<div className={'text-shadow small-detail top-right'}>
					<span>{formattedPrice}</span>
				</div>
			)}

			{distanceOrHeight && (
				<div className={'text-shadow small-detail bottom-left'}>
					<span>
						{isTrekNotPeak ? (
							<Icons.Shoe size="18px" />
						) : (
							<Icons.Mountain
								size="18px"
								color={DisplayUtil.getColorByHeight(height as number)}
							/>
						)}
						&nbsp;
						{distanceOrHeight}
					</span>
				</div>
			)}
		</div>
	)
}

export default TrekCard
