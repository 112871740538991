import * as React from 'react'
import * as MantineCore from '@mantine/core'
//@ts-ignore
import iso3311a2 from 'iso-country-lookup'
import * as Types from '../declarations'
import * as HelperUtil from '../lib/helper'

interface Props {
	treks: Types.App.HomePageTrip[]
	onChange: (countryCodes: string[]) => void
}

// todo: memoize
const countriesData: {
	label: string
	value: string
}[] = Object.entries(iso3311a2.getData() as Record<string, string>).map(
	([value, label]) => ({
		label,
		value,
	}),
)

export const Flag = ({ isoCode }: { isoCode: string }) => (
	<img
		alt={iso3311a2.getCountry(isoCode.toUpperCase())}
		src={HelperUtil.getFlagURL(isoCode)}
		className="flag"
		height={14}
		style={{ marginTop: '-2px' }}
	/>
)

function Value({
	value,
	label,
	onRemove,
	...others
}: MantineCore.MultiSelectValueProps & { value: string }) {
	return (
		<div {...others}>
			<MantineCore.Box
				sx={(theme) => ({
					display: 'flex',
					cursor: 'default',
					alignItems: 'center',
					backgroundColor:
						theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
					border: `${MantineCore.rem(1)} solid ${
						theme.colorScheme === 'dark'
							? theme.colors.dark[7]
							: theme.colors.gray[4]
					}`,
					paddingLeft: theme.spacing.xs,
					borderRadius: theme.radius.sm,
				})}
			>
				<MantineCore.Box mr={10}>
					<Flag isoCode={value} />
				</MantineCore.Box>
				<MantineCore.Box sx={{ lineHeight: 1, fontSize: MantineCore.rem(12) }}>
					{label}
				</MantineCore.Box>
				<MantineCore.CloseButton
					onMouseDown={onRemove}
					variant="transparent"
					size={22}
					iconSize={14}
					tabIndex={-1}
				/>
			</MantineCore.Box>
		</div>
	)
}

// eslint-disable-next-line react/display-name
const Item = React.forwardRef<HTMLDivElement, MantineCore.SelectItemProps>(
	// @ts-ignore
	({ label, value, ...others }: { label: string; value: string }, ref) => {
		return (
			<div ref={ref} {...others}>
				<MantineCore.Flex align="center">
					<MantineCore.Box mr={10}>
						<Flag isoCode={value} />
					</MantineCore.Box>
					<div>{label}</div>
				</MantineCore.Flex>
			</div>
		)
	},
)

const CountryFilterInput = ({ treks, onChange }: Props) => {
	const [value, setValue] = React.useState<string[]>([])

	const arrayOfCountries = treks.map((trek) => trek.countries ?? [])
	const flattenedCountries = arrayOfCountries.flat(1)

	const uniqueCountries: string[] = Array.from(new Set(flattenedCountries))

	const possibleCountries = countriesData.filter((data) =>
		uniqueCountries.includes(data.value),
	)

	React.useEffect(() => {
		if (!value) {
			onChange([])
		} else {
			onChange(value)
		}
	}, [value])

	return (
		<div id="country-filter-input">
			<MantineCore.MultiSelect
				data={possibleCountries}
				limit={20}
				valueComponent={Value}
				itemComponent={Item}
				searchable={true}
				defaultValue={value}
				placeholder="country..."
				disabled={possibleCountries.length < 2}
				onChange={setValue}
				clearable={(value ?? []).length > 1}
				nothingFound={'😞'}
				size="md"
			/>
		</div>
	)
}

export default CountryFilterInput
